import React from 'react';

import Button from '@atlaskit/button/new';

import { AISummaryIcon } from '../../../../common/assets/AISummaryIcon';

type AISummarizeButtonPlaceholderProps = {
	summarizeText: string;
};

export const AISummarizeButtonPlaceholder = ({
	summarizeText,
}: AISummarizeButtonPlaceholderProps) => (
	<Button
		testId="ai-summarize-button-placeholder"
		isDisabled
		iconBefore={() => <AISummaryIcon label="" />}
	>
		{summarizeText}
	</Button>
);
