import React from 'react';

import { SplitButton } from '@atlaskit/button/new';

import { AISummarizeButtonPlaceholder } from '@atlassian/ai-summary';

import { AIContentActionsMenuPopupPlaceholder } from './AIContentActionsMenuPopupPlaceholder';

type AIContentActionsMenuSplitButtonPlaceholderProps = {
	summarizeText: string;
};

export const AIContentActionsMenuSplitButtonPlaceholder = ({
	summarizeText,
}: AIContentActionsMenuSplitButtonPlaceholderProps) => {
	return (
		<SplitButton>
			<AISummarizeButtonPlaceholder summarizeText={summarizeText} />
			<AIContentActionsMenuPopupPlaceholder />
		</SplitButton>
	);
};
